<template>
  <!-- Start Services -->
  <section class="services" id="services">
    <div class="container">
      <div class="main-title text-center">
        <span class="separator">
          <i class="flaticon-chakra"></i>
        </span>
        <h2>What We Do</h2>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service one">
            <div class="service-bg"><i class="flaticon-lotus"></i></div>
            <div class="service-item">
              <div class="service-icon">
                <i class="flaticon-woman-on-lotus-position-front-view"></i>
              </div>
              <h4>Heal your emotions</h4>
              <p>
                Emotional control is just another form of discipline. You have to regulate emotion. You can bring it back at some later stage, but when you know
                you've got something to do, you can remove your thoughts from it, put it in a vault, and get on with what you need to get on with.
                
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service two">
            <div class="service-bg"><i class="flaticon-lotus"></i></div>
            <div class="service-item">
              <div class="service-icon">
                <i class="flaticon-woman-stretching-arms-and-flexing-legs"></i>
              </div>
              <h4>Body &amp; Spirituality</h4>
              <p>
                Clear your mind. Transform your body. Nourish your spirit.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service three">
            <div class="service-bg"><i class="flaticon-lotus"></i></div>
            <div class="service-item">
              <div class="service-icon">
                <i class="flaticon-woman-stretching-her-body-on-the-floor"></i>
              </div>
              <h4>Refreshens your body</h4>
              <p>
                Yoga does not just change the way we see things, it transforms the person who sees.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service four">
            <div class="service-bg"><i class="flaticon-lotus"></i></div>
            <div class="service-item">
              <div class="service-icon">
                <i class="flaticon-woman-stretching-on-floor"></i>
              </div>
              <h4>Enjoy your life</h4>
              <p>
                We all want happiness, and yoga is the way for that much needed happiness factor in life.
                
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service five">
            <div class="service-bg"><i class="flaticon-lotus"></i></div>
            <div class="service-item">
              <div class="service-icon">
                <i class="flaticon-woman-stretching-back"></i>
              </div>
              <h4>Balance Body &amp; Mind</h4>
              <p>
                The mind & body are not separate entities. The gross form of the mind is the body & the subtle
                form of the body is the mind. The practice of asana integrates & harmonizes the two.
              
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service six">
            <div class="service-bg"><i class="flaticon-lotus"></i></div>
            <div class="service-item">
              <div class="service-icon">
                <i
                  class="flaticon-woman-stretching-and-flexing-legs-with-arms-up"
                ></i>
              </div>
              <h4>Mind &amp; Serenity</h4>
              <p>
                You are one yoga class away from a good mood.
                
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Services -->
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped></style>