<template>
  
  <!-- Start Header V1 -->
  <carousel class="home" id="home" :items="1">
    <div
      class="bg-cover"
      style="
    background-image: url('https://i.ibb.co/XFZxrLt/mats.jpg');
      "
    >
      <div class="overlay">
        <div class="home-content">
          <div class="text-center">
            <h5>Welcome To Phoenix Heart Yoga</h5>
            <h1>Join Yoga Studio</h1>
            <p>
              Yoga is the journey of the Self, to the Self, through the Self.
            </p>
            <div class="my-btn">
              <router-link to="/services" class="main-btn" target="_blank"
                ><span>Learn More</span></router-link
              >
              <router-link
                to="/contact-us"
                class="main-btn custom-btn"
                target="_blank"
                ><span>Contact Us</span></router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- New Item -->
    <div
      class="bg-cover"
      style="
        background-image: url('https://i.ibb.co/BTCm3QZ/yoga.jpg');
      "
    >
      <div class="overlay">
        <div class="home-content">
          <div class="text-center">
            <h5>Welcome To Phoenix Heart Yoga</h5>
            <h1>Join Yoga Studio</h1>
            <p>Letting go is the hardest asana.</p>
            <div class="my-btn">
              <router-link to="/services" class="main-btn" target="_blank"
                ><span>Learn More</span></router-link
              >
              <router-link
                to="/contact-us"
                class="main-btn custom-btn"
                target="_blank"
                ><span>Contact Us</span></router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- New Item -->
    <div
      class="bg-cover"
      style="
        background-image: url('https://i.ibb.co/ZxPvhyG/studio.jpg');
      "
    >
      <div class="overlay">
        <div class="home-content">
          <div class="text-center">
            <h5>Welcome To Phoenix Heart Yoga</h5>
            <h1>Join Yoga Studio</h1>
            <p>Just breathe.</p>
            <div class="my-btn">
              <router-link to="/services" class="main-btn" target="_blank"
                ><span>Learn More</span></router-link
              >
              <router-link
                to="/contact-us"
                class="main-btn custom-btn"
                target="_blank"
                ><span>Contact Us</span></router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>

  

  </carousel>

  <!-- End Header V1 -->

</template>

<script>
import carousel from "vue-owl-carousel";
export default {
  components: { carousel, },
};
</script>

<style lang="scss" scoped></style>